import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule, provideStore } from '@ngrx/store';
import { StoreDevtoolsModule, provideStoreDevtools } from "@ngrx/store-devtools";
import '@angular/common/locales/global/ru';

import { provideEnvironmentNgxMask, IConfig } from "ngx-mask";

import { APP_CONFIG } from "./app.config";
import { ApiConfig, AuthServiceConfig } from "./_services";
import { TUSER_FEATURE_KEY, tuserReducer } from './_reducers';
import { ButtonSignoutModule } from "./_components";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from "@angular/router";


const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ButtonSignoutModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature(TUSER_FEATURE_KEY, tuserReducer),
    !APP_CONFIG.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: ApiConfig, useValue: APP_CONFIG.api},
    {provide: AuthServiceConfig, useValue: APP_CONFIG.auth},
    {provide: LOCALE_ID, useValue: 'ru'},
    provideStore(),
    provideStoreDevtools({
      maxAge: 25, // Сохраняет последние 25 состояний.
      logOnly: APP_CONFIG.production, // Значение true отключит расширение redux-devtools-extension.
      autoPause: true, // Приостанавливает запись действий и изменений состояния, когда окно расширения не открыто.
      trace: false, // Если установлено значение true, будет включена трассировка стека для каждого отправленного действия.
      traceLimit: 75, // Максимальное количество сохраняемых кадров трассировки стека.
      connectInZone: true}),
    provideEnvironmentNgxMask(maskConfig),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
